import React from 'react'
import PageHeader from '../components/PageHeader'
import Layout from '../components/layout'
import { FormattedMessage } from 'gatsby-plugin-intl'

import {
  Header,
  Image,
  Segment,
  Container,
  Divider,
  List,
} from 'semantic-ui-react'

import './expertise.css'

import consulting from '../images/Badge.png'
import developer from '../images/Certifications/developer-associate.png'
import architect from '../images/Certifications/solutions-architect-associate.png'
import sysops from '../images/Certifications/sysops-associate.png'
import sa_pro from '../images/Certifications/solutions-architect-professional.png'
import devops from '../images/Certifications/devops-engineer-professional.png'
import big_data from '../images/Certifications/big-data-specialty.png'
import ml from '../images/Certifications/machine-learning-specialty.png'

const CertificationsPrimary = () => (
  <Segment vertical>
    <Segment as="section" basic>
      <Container text>
        <Header as="h3">
          <FormattedMessage id="mission-introduction-content" />
        </Header>
        <List as="ul">
          <List.Item as="li">
            <FormattedMessage id="content-expertise-1-li-1" />{' '}
          </List.Item>
          <List.Item as="li">
            <FormattedMessage id="content-expertise-1-li-2" />{' '}
          </List.Item>
          <List.Item as="li">
            <FormattedMessage id="content-expertise-1-li-3" />{' '}
          </List.Item>
          <List.Item as="li">
            <FormattedMessage id="content-expertise-1-li-4" />{' '}
          </List.Item>
          <List.Item as="li">
            <FormattedMessage id="content-expertise-1-li-5" />{' '}
          </List.Item>
          <List.Item as="li">
            <FormattedMessage id="content-expertise-1-li-6" />{' '}
          </List.Item>
        </List>
      </Container>
    </Segment>
    <Container text>
      <Divider />
    </Container>
    <Segment as="section" basic>
      <Container text>
        <Header as="h3">
          <FormattedMessage id="subtitle-expertise-2" />
        </Header>
        <p align="justify">
          <FormattedMessage id="content-expertise-2" />
        </p>
      </Container>
    </Segment>
    <Container text>
      <Divider />
    </Container>
    <Segment as="section" basic>
      <Container text>
        <Header as="h3">
          <FormattedMessage id="subtitle-expertise-3" />
        </Header>
        <div className="fusion">
          <div className="partner-icon">
            <a
              href="https://aws.amazon.com/partners/find/partnerdetails/?n=Mangrove%20Consulting&id=0010h00001aCXe0AAG"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Image
                src={consulting}
                className="consulting"
                size="medium"
                alt="AWS Consulting Partner"
              />
            </a>
          </div>
          <div className="partner">
            <p align="justify">
              <FormattedMessage id="content-expertise-3" />
            </p>
          </div>
        </div>
      </Container>
    </Segment>
    <Container text>
      <Divider section />
    </Container>
    <Segment as="section" basic>
      <Container text>
        <Header as="h3">
          <FormattedMessage id="subtitle-expertise-4" />
        </Header>
        <p align="justify">
          <FormattedMessage id="content-expertise-4" />
        </p>
      </Container>
      <Container className="certifications-icons">
        <Image.Group size="small">
          <Image src={developer} />
          <Image src={architect} />
          <Image src={sysops} />
          <Image src={sa_pro} />
          <Image src={devops} />
          <Image src={big_data} />
          <Image src={ml} />
        </Image.Group>
      </Container>
    </Segment>
  </Segment>
)

const Certifications = () => (
  <Layout>
    <div className="CertificationsPage">
      <PageHeader>
        <p>
          <FormattedMessage id="content-expertise" />
        </p>
      </PageHeader>
      <CertificationsPrimary />
    </div>
  </Layout>
)

export default Certifications
